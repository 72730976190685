import * as Kaleidoscope from './kaleidoscope.es.js';

export default class Slider {
    constructor(sliderSelector, panaramaSelector) {
        this.sliderTimeout;
        this.wrap = document.querySelector(sliderSelector);
        this.slides = Array.from(this.wrap.querySelectorAll(sliderSelector + '__item'));
        this.activeIndex = this.getActiveIndex();

        const panaramaEl = document.querySelector(panaramaSelector);
        const _self = this;
        this.initYaw = -134;

        this.panarama = new Kaleidoscope.Image({
            source: panaramaEl.dataset.img1920, 
            containerId: panaramaSelector,
            height: _self.wrap.clientHeight,
            width: _self.wrap.clientWidth,
            verticalPanning: false,
            initialYaw: this.initYaw,
            onDragStop: function() {
                const isNext = this.rotateDelta.x >= 0;
                _self.changeSlide(!isNext);
            }
        });

        this.panarama.render();

        $(window).on('load resize', () => {
            const height = this.wrap.clientHeight;
            const width = this.wrap.clientWidth;

            this.panarama.setSize({ width, height });
        });
    }

    // getWallpaper(path) {
    //     const type = $(window).hasClass('webp') ? 'webp' : 'jpg';
    //     let currentPath = path.replace('1920.jpg', '');
    //     const winSize = $(window).innerWidth();

    //     if(winSize > 1500) {
    //         currentPath += '1920';
    //     } else if(winSize > 1000) {
    //         currentPath += '1500';
    //     } else if(winSize <= 1000) {
    //         currentPath += '1000';
    //     }

    //     currentPath += `.${ type }`;

    //     return currentPath;

    // }

    getActiveIndex() {
        return this.slides.findIndex(slide => {
            return slide.classList.contains('active');
        });
    }

    nextIndex() {
        return this.activeIndex >= this.slides.length - 1 ? 0 : this.activeIndex + 1;
    }
    prevIndex() {
        return this.activeIndex > 0 ? this.activeIndex - 1 : this.slides.length - 1;
    }

    changeSlide(isNext = true) {
        this.stop();

        const initIndex = this.activeIndex;
        this.activeIndex = isNext ? this.nextIndex() : this.prevIndex();

        this.panarama.controls.initialYaw += isNext ? -90 : 90;
        this.panarama.controls.centralize();

        this.slides[this.activeIndex].classList.add('active');
        this.slides[initIndex].classList.remove('active');
    }

    start(duration) {
        const _self = this;

        loop();
        function loop() {
            _self.sliderTimeout = setTimeout(() => {
                _self.changeSlide();
                loop();
            }, duration);
        }
    }

    stop() {
        if(this.sliderTimeout) {
            clearTimeout(this.sliderTimeout);
        }
    }
}
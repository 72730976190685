export class Menu {
    constructor($menu, $toggleBtn) {
        this.btn = $toggleBtn;
        this.menu = $menu;
        this.items = $menu.children().children().not('.external');
        this.sections = [];

        this.items.each((el, target) => {
            this.sections.push(target.getAttribute('href'));
        });

        this.btn.on('click', () => {
            this.toggleMenu();
        });
        this.items.on('click', (e) => {
            const anchor = e.target.getAttribute('href');
            const regex = new RegExp(/\//, 'gi');
            if(regex.test(anchor)) {

            } else {
                e.preventDefault();
                this.scrollTo(anchor);
                this.toggleMenu();
                this.checkIt($(e.target));
            }
        });

        $(window).on('keyup', (e) => {
            switch(e.code) {
                case 'Escape':
                    this.toggleMenu();
                    break;
            }
        });
    }

    toggleMenu() {
        [this.menu, this.btn].map(($el) => {
            $el.toggleClass('active');
        });
    }
    scrollTo(anchor) {
        if($(anchor).length) {
            const offset = $(anchor).offset().top;
            $("html, body").stop().animate({
                scrollTop: offset + 'px'
            }, 500);
        } else {
            throw new Error(`Не удалось выполнить переход к разделу: ${ anchor }`);
        }
            
    }
    initDots($el) {
        this.dots = $el.children();
        this.checkCurrentSection();

        this.dots.on('click', (e) => {
            this.scrollTo('#' + e.target.dataset.section);
        });

        // событие окончания скролла, чтобы не просчитывать каждый пиксель
        let timeout = false;
        $(window).on('scroll', () => {
            if (timeout !== false) { clearTimeout(timeout); }
            timeout = setTimeout(() => {
                let sectionIndex = this.checkCurrentSection();
                this.checkIt(this.items.eq(sectionIndex));

                $(window).trigger('scrollend');
            }, 50);
        });
    }
    checkCurrentSection() {
        const currentTop = $(window).scrollTop();
        const covered = this.sections.filter((sectionID, index) => {
                const $section = $(sectionID);
                if($section.length) {
                    const top = Math.round($section.offset().top);
                    return currentTop + 50 >= top ? true : false;
                }
            });

        const dotIndex = covered.length - 1;

        try {
            const currentDot = this.dots.eq(dotIndex);
            this.checkIt(currentDot);
            this.setHash(this.sections[dotIndex]);
            // скрываем точки на экране, помеченном классом unvisible в блоке с точками
            if(currentDot.hasClass('unvisible')) {
                this.dots.parent().removeClass('active');
            } else {
                this.dots.parent().addClass('active');
            }
            return dotIndex;
        } catch (err) {
            throw new Error(err);
        }
    }

    setHash(anchor) {
        window.history.pushState(null, null, anchor);
    }

    checkIt($el) {
        $el.addClass("active").siblings().removeClass("active");
    }
}
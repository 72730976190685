import './OverlayScrollbars/css/OverlayScrollbars.min.css';
import './OverlayScrollbars/js/jquery.overlayScrollbars.min.js';

renderTop50('https://my.leadgid.com/reports/contest-around-the-world');

function renderTop50(jsonUrl) {
    $.getJSON(jsonUrl)
        .done(function(data) {
            data.forEach((row, index) => {
                const table = document.querySelector('.top50Table tbody');
                const strRow = `<tr><td>${ index + 1 }</td><td>${ row.affiliate }</td><td>${ row.points }</td></tr>`;
                
                table.innerHTML += strRow;

                $('.top50__wrap').overlayScrollbars({
                    autoUpdate: true,
                    overflowBehavior: {
                        x: 'hidden',
                        y: 'scroll',
                    }
                });

            });
        });
}

import './jquery.formstyler.js';

const langSwitcher = $('#lang-select');
const currentLang = $('#lang-select').val();

langSwitcher.styler();
$(".language-change").removeClass("init");

langSwitcher.on('change', function() {
    const locale = $(this).val();
    let url = window.location.toString().split("#");
    let newurl = url[0].replace(currentLang, locale);
    newurl += url[1] ? '#' + url[1] : '';

    window.location.href = newurl;
});
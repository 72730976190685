export default class SVGPath {
  constructor(pathID) {
    this.path = document.getElementById(pathID);
    this.pathLength = this.path.getTotalLength();
  }
  init(triggerID = null) {
    const triggerScrolltop = triggerID ? $('#' + triggerID).offset().top - 100 : 0;
    const pathHeight = Math.round($(".travelPath__front").height());
    const windowHeight = document.documentElement.clientHeight;

    this.path.style.strokeDasharray = this.pathLength + ' ' + this.pathLength;
    this.path.style.strokeDashoffset = this.pathLength;

    const $imgLeadgid = $('.travelPath__img.leadgid');
    const $imgCoconut = $('.travelPath__img.coconut');
    const $imgWatch = $('.travelPath__img.watch');
    const $imgCompass = $('.travelPath__img.compas');
    const $imgShip = $('.travelPath__img.ship');
    const $imgAntiquity = $('.travelPath__img.antiquity');

    $(window).on("load scroll", () => {
      const windowScrolltop = $(window).scrollTop();
      const scrollPercentage = (windowScrolltop - triggerScrolltop) / (pathHeight - windowHeight / 4);
      const percents = scrollPercentage < 0 ? 0 :
                          scrollPercentage > 1 ? 1 : scrollPercentage;
      const drawLength = this.pathLength * percents;

      this.path.style.strokeDashoffset = this.pathLength - drawLength;
      this.path.style.strokeDasharray = this.pathLength + ' ' + this.pathLength;

      // add animations for images
      this.showIt($imgLeadgid, 0, drawLength);
      this.showIt($('.travelPath__item.fourth .travelPath__title'), 1500, drawLength);
      this.showIt($imgCoconut, 1800, drawLength);
      this.showIt($('.travelPath__item.fifth .travelPath__title'), 3400, drawLength);
      this.showIt($imgWatch, 4200, drawLength);
      this.showIt($imgCompass, 5150, drawLength);
      this.showIt($('.travelPath__item.seventh .travelPath__title'), 6000, drawLength);
      this.showIt($imgShip, 7100, drawLength);
      this.showIt($('.travelPath__item.ninth .travelPath__title'), 9320, drawLength);
      this.showIt($imgAntiquity, 10300, drawLength);
    });
  }
  showIt($el, minLength, currentLength) {
    $el[currentLength > minLength ? 'addClass' : 'removeClass']('fadeIn');
  }
}
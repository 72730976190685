import "./js/owl/owl.carousel.css";
import "./index.scss";
import "./js/jquery.js";
import "./js/modernizr.js";
import Slider from "./js/mainSlider.js";
import { Menu } from "./js/navigation.js";
import "./js/owl/owl.carousel.min.js";
import * as WOW from "./js/wow.js";
import SVGPath from './js/svgpath.js';
import "./js/lang.js";
import "./js/top50.js";

let menu = new Menu($(".menu"), $(".navigation__btn"));
menu.initDots($(".navigationDots"));

// svg path
const contestPath = new SVGPath("dash-path");
contestPath.init('path-trigger');

// waves
const waveVideo = document.getElementById('waveVideo');
playVisibleVideo(waveVideo);


// scrolltop
const $scrolltopButton = $(".scrolltop");
let lastScrollTop = 0;

$scrolltopButton.on("click", function() {
    menu.scrollTo("#home");
});

$(window).on("scrollend", function() {
    const scrolltop = $(window).scrollTop(),
        st = window.pageYOffset || document.documentElement.scrollTop,
        screenHeight = $(window).innerHeight();

    if (scrolltop > screenHeight) {
        $scrolltopButton.addClass("active");
    } else {
        $scrolltopButton.removeClass("active");
    }

    if (scrolltop + screenHeight >= Math.round($(".footer").offset().top) + $(".footer").innerHeight() / 2) {
        $scrolltopButton.addClass("footer");
    } else {
        $scrolltopButton.removeClass("footer");
    }
    
    playVisibleVideo(waveVideo);
    
});

$('#devicestore-slider').owlCarousel({
    loop: true,
    margin: 0,
    dots: true,
    nav: false,
    dotsContainer: '.owl-dots',
    responsiveClass: true,
    responsive: {
        0: {
            items: 2
        },
        601: {
            items: 3
        },
        801: {
            items: 4
        },
        1001: {
            items: 5
        }
    }
})

const box = $("#spinner");

$(document).on('mousemove', function(e) {
    var x = e.pageX;
    var y = e.pageY;
    var boxCenter = [
        box.offset().left + box.width() / 2,
        box.offset().top + box.height() / 2
    ];
    
    window.onresize = function() {
        var x = e.pageX;
        var y = e.pageY;
        var boxCenter = [
            box.offset().left + box.width() / 2,
            box.offset().top + box.height() / 2
        ];
        init();
    }
    
    function init(){
        const angle = Math.atan2(x - boxCenter[0], -(y - boxCenter[1])) * (180 / Math.PI);
        box.css({ "-webkit-transform": "rotate(" + angle + "deg)" });
        box.css({ "-moz-transform": "rotate(" + angle + "deg)" });
        box.css({ transform: "rotate(" + angle + "deg)" });
    }
    init();
});

$('#travel-slider').owlCarousel({
    loop: true,
    margin: 0,
    dots: true,
    nav: false,
    dotsContainer: '.tabs-dotted',
    responsiveClass: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 10000
});

new WOW().init();

// slider
const sliderDuration = 7000;
if ($(window).innerWidth() > 680) {
    const mainSlider = new Slider('.mainSlider', '#panarama');
    mainSlider.start(sliderDuration);
} else {
    const $mainSlider = $("#mainSlides");
    const $sliderItems = $mainSlider.find(".mainSlider__item");
    const sliderLength = $sliderItems.length;
    setInterval(() => {
        const activeSlideIndex = $mainSlider.find('.active').index();
        const nextSlideIndex = activeSlideIndex >= sliderLength - 1 ? 0 : activeSlideIndex + 1;

        $sliderItems.eq(activeSlideIndex).removeClass('active');
        $sliderItems.eq(nextSlideIndex).addClass('active');
    }, sliderDuration);
}

function playVisibleVideo(video) {
    if(window.innerWidth >= 1200) {
        if (isPlayVideo($('#waveVideo'), window.innerHeight)) {
            if (video.querySelector('source') === null) {
                const waveVideoSource = createVideoSource(video);
                video.appendChild(waveVideoSource);
            }

            if(video.currentTime === 0) {
                video.play();
            }
        } else {
            video.pause();
            video.currentTime = 0;
        }
    }
}

function isPlayVideo($el, limit = 100) {
    const scrollTop = $(window).scrollTop();
    const topEdge = $el.offset().top;
    const bottomEdge = topEdge + $(window).innerHeight();

    return scrollTop >= topEdge - limit && scrollTop <= bottomEdge + limit;
}

function createVideoSource(video) {
    const source = document.createElement('source');
    source.type = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
    source.codecs = 'avc1.42E01E, mp4a.40.2';
    source.src = video.dataset.video;
    return source;
}


function pathPrepare($el) {
    var lineLength = $el[0].getTotalLength();
    console.log(lineLength)
    $el.css("stroke-dasharray", lineLength);
    $el.css("stroke-dashoffset", lineLength);
}